





















































































































































































import Vue from 'vue';
import LookupContentStatusRequestViewModel from '@/src/services/viewModel/request/LearnitBaseData/LookupContentStatusRequestViewModel';
import LookupLevelRequestViewModel from '@/src/services/viewModel/request/LearnitBaseData/LookupLevelRequestViewModel';
import LookupContentTypeRequestViewModel from '@/src/services/viewModel/request/LearnitBaseData/LookupContentTypeRequestViewModel';
import LookupCompetenceAreaRequestViewModel from '@/src/services/viewModel/request/LearnitBaseData/LookupCompetenceAreaRequestViewModel';
import LearningPathUnitResourceViewModel from '@/src/services/viewModel/resource/LearningPathUnitResourceViewModel';
import LookupTopicRequestViewModel from '@/src/services/viewModel/request/LearnitBaseData/LookupTopicRequestViewModel';
import UploadDocumentRequestViewModel from '@/src/services/viewModel/request/Documents/UploadDocumentRequestViewModel';
import LearningPathUnitTagResourceViewModel from '@/src/services/viewModel/resource/LearningPathUnit_TagResourceViewModel';
import LearnitTagResourceViewModel from '@/src/services/viewModel/resource/LearnitTagResourceViewModel';
import LookupTagRequestViewModel from '@/src/services/viewModel/request/LearnitTags/LookupTagRequestViewModel';
import UploadZipDocumentRequestViewModel from '@/src/services/viewModel/request/Documents/UploadZipDocumentRequestViewModel';
import GetFoldersRequestViewModel from '@/src/services/viewModel/request/Documents/GetFoldersRequestViewModel';

export default Vue.extend({
  auth: true,
  role: ['Admin', 'Hostadmin'],
  data: () => ({
    unassignedTags: [] as LearnitTagResourceViewModel[],
    assignedTags: [] as LearnitTagResourceViewModel[],
    filterUnassignedTags: new LookupTagRequestViewModel(),
    isLoadingTags: false,
    createOrUpdateLearningUnit: new LearningPathUnitResourceViewModel(),
    levels: [] as any,
    authoringFolders: [] as any,
    competenceAreas: [] as any,
    topics: [] as any,
    columns: [] as {}[],
    contentTypes: [] as any,
    status: [] as any,
    isLoading: false,
    picture: [] as File[],
    errorResponse: undefined as any,
    uploadDialogErrorResponse: undefined as any,
    successMessage: undefined as string | undefined,
    uploadFolderSuccessMessage: undefined as string | undefined,
    isAddTagDialogVisible: false,
    tagIdToAdd: undefined as number | undefined,
    isAddFolderVisible: false,
    zipFile: [] as File[],
    uploadFolderName: undefined as string | undefined,
  }),
  computed: {
    // isUploadFolderNameValid(): boolean {
    //   if (this.validatedFolderName?.match(/^[a-zA-Z-1-9-]+$/g)) {
    //     return true;
    //   }
    //   return false;
    // },
    assignedTagsIds(): number[] {
      return this.assignedTags.map((tag) => (tag.id as any)?.value ?? tag.id).filter((id) => this._.isSet(id));
    },
    learningUnitId() {
      const { learningUnitId } = this.$router.currentRoute.params;
      if (learningUnitId) {
        return parseInt(learningUnitId, 10);
      }
      return undefined;
    },
    isEditMode(): boolean {
      if (this.learningUnitId) return true;
      return false;
    },
    pageTitle(): string {
      if (this.createOrUpdateLearningUnit.title && this.createOrUpdateLearningUnit.title.length > 0) {
        return this.createOrUpdateLearningUnit.title;
      }
      if (this.isEditMode) {
        return this.$t('page.learningUnits.title.edit');
      }
      return this.$t('page.learningUnits.title.create');
    },
  },
  watch: {
    filterUnassignedTags: {
      deep: true,
      handler() {
        this.loadUnassignedTags();
      },
    },

  },
  async mounted() {
    this.isLoading = true;
    this.columns = [
      // { value: '', field: 'sort-actions' },
      { value: 'Titel', textAlign: 'left', field: 'caption' },
      { value: '', field: 'actions', width: '1px' },
    ];

    const levels = await this.$service.api.learnitBaseData.lookupLevel(new LookupLevelRequestViewModel());
    const status = await this.$service.api.learnitBaseData.lookupContentStatus(new LookupContentStatusRequestViewModel());
    const contentTypes = await this.$service.api.learnitBaseData.lookupContentType(new LookupContentTypeRequestViewModel());
    const competenceAreas = await this.$service.api.learnitBaseData.lookupCompetenceArea(new LookupCompetenceAreaRequestViewModel());
    const topics = await this.$service.api.learnitBaseData.lookupTopic(new LookupTopicRequestViewModel());
    this.levels = levels.items;
    this.status = status.items;
    this.contentTypes = contentTypes.items;
    this.competenceAreas = competenceAreas.items;
    this.topics = topics.items;

    if (this.learningUnitId) {
      this.createOrUpdateLearningUnit = await this.$service.api.learnitUnits.getLearningPathUnitById(this.learningUnitId);
      await this.loadAssignedTags();
      await this.getAuthoringToolFolders();
    }
    // filehandling
    if (this.createOrUpdateLearningUnit.pictureDocumentId) {
      try {
        const blob = await this.$service.api.documents.downloadDocument(this.createOrUpdateLearningUnit.pictureDocumentId);
        const image = new Blob([blob], { type: 'image/*' });
        this.picture.push(image as File);
      } catch {
        this.createOrUpdateLearningUnit.pictureDocumentId = undefined;
      }
    }
    this.isLoading = false;
  },
  methods: {
    cancel() {
      this.$router.back();
    },
    async save() {
      this.errorResponse = undefined as any;
      if (!this.createOrUpdateLearningUnit.validate()) {
        return;
      }
      try {
        this.errorResponse = undefined;
        if (this.learningUnitId) {
          await this.$service.api.learnitUnits.updateLearningPathUnit(this.learningUnitId, this.createOrUpdateLearningUnit);
          this.successMessage = this.$t('page.learningUnits.successMessage.updateLearningUnit');
        } else {
          const id = await this.$service.api.learnitUnits.createLearningPathUnit(this.createOrUpdateLearningUnit);
          await this.$router.replace(`/ktgl/learnit/learningUnits/${id}/edit`);
          this.successMessage = this.$t('page.learningUnits.successMessage.createLearningUnit');
        }
      } catch (error : any) {
        this.errorResponse = error;
      }
    },
    async onFileSelected(files: File[]) {
      const [file] = files;
      let id = undefined as number | undefined;
      if (file) {
        const req = new UploadDocumentRequestViewModel();
        req.uploadFile = file;
        id = await this.$service.api.documents.uploadDocument(req);
        this.createOrUpdateLearningUnit.pictureDocumentId = id;
      }
    },
    async deleteLearningUnit() {
      this.isLoading = true;
      try {
        const isDeleteConfirmed = await this.$dialog.confirm(this.$t('app.delete.confirmation.title'), this.$t('app.delete.confirmation.text'));
        if (isDeleteConfirmed) {
          await this.$service.api.learnitUnits.deleteLearningPathUnit(this.learningUnitId!);
          this.$router.push('/ktgl/learnit/learningUnits/');
        }
      } catch (error) {
        this.$log.error(error);
        this.errorResponse = error;
      }
      this.isLoading = false;
    },
    goToLearningUnitOverview() {
      this.$router.push('/ktgl/learnit/learningUnits');
    },
    async loadUnassignedTags() {
      const response = await this.$service.api.learnitTags.lookupTag(this.filterUnassignedTags);
      this.unassignedTags = response.items.map((e : any) => ({ ...e.toDTO() }));
      this.unassignedTags = this.unassignedTags.filter((i) => !this.assignedTagsIds.includes(i.id!));
    },
    async loadAssignedTags() {
      const response = await this.$service.api.learnitUnits.getTagsByLearningPathUnitId(this.learningUnitId!);
      this.assignedTags = response.items.map((e: any) => ({ ...e.toDTO() }));
      await this.loadUnassignedTags();
    },
    async addTag(entry : any) {
      this.isLoadingTags = true;
      this.tagIdToAdd = undefined;
      this.isAddTagDialogVisible = false;
      if (this._.isSet(this.learningUnitId)) {
        const o = new LearningPathUnitTagResourceViewModel();
        o.learnitTagId = entry?.id!;
        o.learningPathUnitId = this.learningUnitId;
        await this.$service.api.learnitUnits.addTagToLearningPathUnit(o);
      }
      this.assignedTags.push(entry);
      await this.loadUnassignedTags();
      this.isLoadingTags = false;
    },
    // textToSlug(text: string) {
    //   return text
    //     .toLowerCase()
    //     .trim()
    //     .replace(/[^\w\s-]/g, '')
    //     .replace(/[\s_-]+/g, '-')
    //     .replace(/^-+|-+$/g, '');
    // },
    async removeTag(entry : any) {
      this.isLoadingTags = true;
      if (this._.isSet(this.learningUnitId)) {
        await this.$service.api.learnitUnits.removeTagFromLearningPathUnit(this.learningUnitId!, entry.id);
      }
      this.$set(this, 'assignedTags', this.assignedTags.filter((t) => ((t as any).id?.value ?? t.id) !== entry.id));
      await this.loadAssignedTags();
      this.isLoadingTags = false;
    },
    async openUploadFolderDialog() {
      this.isAddFolderVisible = true;
      this.uploadFolderName = this.createOrUpdateLearningUnit.shortCode;
    },
    async uploadZipDocument(files: File[], folderName: string | undefined) {
      this.isLoading = true;
      const [file] = files;
      const o = new UploadZipDocumentRequestViewModel();
      o.entityId = this.learningUnitId;
      o.entityType = 'units';
      o.uploadFilename = folderName;
      o.uploadFile = file!;
      try {
        await this.$service.api.learnitUnits.updateLearningPathUnit(this.learningUnitId!, this.createOrUpdateLearningUnit);
        await this.$service.api.documents.uploadZipDocument(o);
        this.createOrUpdateLearningUnit = await this.$service.api.learnitUnits.getLearningPathUnitById(this.learningUnitId!);
        await this.getAuthoringToolFolders();
        this.uploadFolderSuccessMessage = this.$t('app.unitOrPath.uploadAuthoringTool.label.uploadSuccessMessage');
        this.isAddFolderVisible = false;
      } catch (error) {
        this.$log.error(error);
        this.uploadDialogErrorResponse = error;
      }
      this.isLoading = false;
    },
    async getAuthoringToolFolders() {
      const o = new GetFoldersRequestViewModel();
      o.entityId = this.learningUnitId;
      o.entityType = 'units';
      try {
        const result = await this.$service.api.documents.getFolders(o);
        this.authoringFolders = (result.items ?? []).map((i, index) => ({
          id: i,
          caption: i,
          details: i,
          sort: index,
        }));
      } catch (error) {
        this.$log.error(error);
        this.errorResponse = error;
      }
    },
  },
});
